export const environment = {
	production: true,

	defaultLocationAddress: 'Fairfield, CT',
	defaultLocationLat: '41.1408363',
	defaultLocationLng: '-73.26126149999999',
	private_key: 'PI@!2N#DO',
	public_key: 'PIN2#D@!O',

	//  BETA PINDO
	/* stripe_client_id: 'ca_F4XIBJkhf63PuNYlcY05ItPmG72fgV5E',
	baseUrl: window.location.protocol + '//' + window.location.hostname + '/pindo-server/api',
	chatUrl: window.location.protocol + '//' + window.location.hostname,
	communityUrl: window.location.protocol + '//' + window.location.hostname + ':4046', */

	// PRODUCTION PINDO
	stripe_client_id: 'ca_F4XIPAExOZWq3IPNXWRzX7W4lCgCEquM',
	baseUrl: window.location.protocol + '//' + window.location.hostname + '/pindo-server/api',
	chatUrl: window.location.protocol + '//' + window.location.hostname,
	communityUrl: window.location.protocol + '//' + window.location.hostname + ':4047',
};
